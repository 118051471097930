.button {
    font-weight: 500;
    font-size: 16px;
    cursor: pointer;
    transition: all 0.5s ease;
}

.button.primary {
    outline: none;
    padding: 16px 32px;
    border: none;
    letter-spacing: 1px;
    background-image: linear-gradient(45deg, var(--dark-orange) 0%, var(--light-orange) 81%, var(--dark-orange)100%);
    color: white;
    box-shadow: 0px 20px 40px rgba(0, 0, 0, 0.11);
    border-radius: 30px;
    margin-top: 1rem;
}

.button span {
    cursor: pointer;
    display: inline-block;
    position: relative;
    transition: 0.5s;
}

.button span:after {
    position: absolute;
    opacity: 0;
    top: 0;
    right: -20px;
    transition: 0.5s;
}

.button.primary span::after {
    content: '\00bb';
}

.button.button.secondary span::after {
    font-family: "Font Awesome 6 Free";
    content: "\f090";
    font-weight: 900;
}

.button:hover span {
    padding-right: 25px;
}

.button:hover span:after {
    opacity: 1;
    right: 0;
}

.readmore__container {
    margin-top: -3rem;
}

.button.secondary {
    text-transform: none;
    color: var(--primary);
    border-radius: 8px;
    background-color: #fff;
    border: 1px solid var(--primary);
    padding: 12px 32px;
    font-weight: 500;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}