
/* Video section */
#fair-principles {
  background-color: var(--dark-blue);
}
#fair-principles p {
  color: var(--gray10);
  margin-bottom: 24px;
}
#fair-principles h3 {
  color: var(--gray00);
  font-size: 30px;
  font-weight: 500;
}
.video__container {
  justify-content: center;
  align-items: center;
  position: relative;
  overflow: hidden;
  width: 100%;
  aspect-ratio: 16/9;
}
.responsive-iframe {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
}

h3 strong {
  color: var(--light-orange);
}

@media screen and (min-width: 900px) {
  .fair-principles_wrapper {
    display: flex;
    justify-content: space-between;
  }
  .video__container {
    width: 50%;
  }
  .fair-principles_title {
    width: 45%;
    display: flex;
    text-align: center;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}