/* NAVIGATION */
#navbar {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 100;
  background-color: var(--dark-blue);
  transition: all 0.5s ease 0s;
}

.navbar__container {
  height: var(--navbar-height);
  padding: 0 10vw;
  justify-content: space-between;
  align-items: center;
  transition: 0.5s all ease;
}

.navbar__logo {
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  height: 60px;
  width: 80px;
}

.progress-bar {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 5px;
  background: var(--dark-orange);
  transform-origin: 0%;
}

/* MOBILE NAVBAR */
.navbar__hamburger {
  font-size: 25px;
  height: var(--navbar-height);
  justify-content: center;
  align-items: center;
  transition: all 1s;
  color: #fff;
  cursor: pointer;
}

.navbar__dropdown_mobile {
  position: absolute;
  top: var(--navbar-height);
  right: 0;
  border-bottom-left-radius: 6px;
  width: 100vw;
  background-color: var(--dark-blue);
  padding: 20px 0 10px 0;
  border-bottom-right-radius: 6px;
}

.navbar__internal_links,
.navbar__external_links {
  text-align: right;
  flex-direction: column;
}

.navbar__internal_links a,
.navbar__external_links a {
  color: #fff;
  text-decoration: none;
  transition: 0.5s ease all;
  text-transform: uppercase;
  padding-right: 10vw;
  padding-bottom: 2rem;
  display: flex;
  justify-content: center;
  font-size: 18px;
}

.navbar__external_links h5 {
  padding-right: 10vw;
  text-transform: uppercase;
  color: var(--secondary);
  font-size: 17px;
  position: relative;
}

.navbar__external_links h5::after {
  content: "";
  position: absolute;
  display: block;
  width: 100px;
  height: 1px;
  background: var(--secondary);
  bottom: -10px;
  right: 10vw;
}

/* DESKTOP NAVIGATION */
/* Not displayed on small screens */
.navbar__desktop {
  display: none;
}

@media only screen and (min-width: 1000px) {
  .navbar__hamburger, .navbar__dropdown_mobile {
    display: none;
  }
  .navbar__desktop {
    display: flex;
  }
  .navbar__internal_links a:hover {
    letter-spacing: 1px;
    color: var(--light-orange);
  }
  .navbar__internal_links {
    flex-direction: row !important;
    display: flex;
  }
  .navbar__internal_links a {
    padding-right: 20px;
    padding-bottom: 0;
    display: block;
    font-size: 16px;
  }
  .navbar__internal_links a:last-of-type {
    padding-right: 0;
  }
  .navbar__container {
    padding: 0 15vw;
  }
}
