/* HERO */
#hero {
  margin-top: var(--navbar-height);
}
header {
  border-bottom: 1px solid var(--gray05);
}

.hero:before {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: hsl(212, 100%, 12%, 0.2);
}

.hero {
  height: calc(85vh - var(--navbar-height));
  background-image: url("../assets/img/hub_illustration.png");
  background-size: 60%;
  background-repeat: no-repeat;
  background-position: bottom right;
  position: relative;
  background-color: var(--dark-blue);
}

.hero__title {
  height: 75vh;
  justify-content: center;
  align-items: center;
  margin-top: calc(0px - var(--navbar-height));
  text-align: center;
  position: relative;
  padding: 0 16px;
}

.hero__title_small {
  margin-bottom: var(--navbar-height);
  z-index: 5;
}

h1 {
  font-weight: 700;
  font-size: 32px;
  letter-spacing: -1px;
  margin: 0;
  text-shadow: 0 0 50px hsla(0, 0%, 0%, 0.8);
  color: var(--light-blue);
}

.hero__title h2 {
  color: var(--gray00);
  font-size: 40px;
  font-weight: 600;
  text-shadow: 0 0 50px hsla(0, 0%, 0%, 0.8);
  margin: 0.5rem 0;
}

.spacer {
  z-index: 0;
  aspect-ratio: 960/460;
  width: 100%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  position: absolute;
  bottom: 0;
}

.spacer.top {
  top: -50px;
  transform: rotate(180deg);
}

.hero__logos {
  height: 15vh;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.hero__logo {
  width: 200px;
  height: 100px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

.hero__logos :last-child {
  margin-bottom: 25px;
}

.hero__button {
  margin-top: 16px;
  padding: 16px 32px;
  text-transform: uppercase;
  transition: 0.5s;
  background-size: 200% auto;
  color: var(--gray00);
  border-radius: 10px;
  border: 0px;
  font-weight: 700;
  box-shadow: 0px 0px 14px -7px var(--dark-orange);
  background-image: linear-gradient(
    45deg,
    var(--dark-orange) 0%,
    var(--light-orange) 51%,
    var(--dark-orange) 100%
  );
  cursor: pointer;
  font-size: 12px;
}

.hero__button:hover {
  background-position: right center;
}

@media only screen and (min-width: 600px) {
  .hero__logos {
    flex-direction: row;
  }

  .hero__logos :last-child {
    margin-left: 10px;
  }

  .hero__logos :first-child {
    margin-right: 10px;
  }

  .hero__logo {
    width: 220px;
    margin-bottom: 15px !important;
  }
}

@media screen and (min-width: 900px) {
  .spacer {
    aspect-ratio: 960/160;
  }
  .spacer.top {
    aspect-ratio: 960/260;
    max-height: 300px;
  }

  .hero__title {
    text-align: left;
    justify-content: flex-start;
  }
  .hero__title_small {
    padding-left: 18vw;
    max-width: 600px;
    margin-bottom: 0;
  }
  .hero__title h2 {
    font-size: 48px;
  }
}

@media only screen and (min-width: 1200px) {
  .hero__logo {
    width: 300px;
  }
  .hero::before {
    background-color: unset;
  }
  .hero {
    background-size: contain;
    background-position: right;
  }
}

@media only screen and (min-width: 1600px) {
  .hero__title_small {
    padding-left: 18vw;
    max-width: 650px;
  }
  .hero__title h2 {
    font-size: 52px;
  }
}
