.blog {
  margin-top: 2rem;
}

#blog-page h2 {
  font-size: 28px;
  margin: 30px 0;
  text-align: left;
}

#blog-page h3 {
  margin: 20px 0;
  text-align: left;
  font-size: 22px;
}

#blog-page a {
  color: var(--light-blue);
  text-decoration: none;
}

#blog-page p,
#blog-page li {
  line-height: 1.8;
  /* font-size: 18px; */
  margin-bottom: 1rem;
}

#blog-page li {
  margin: 0.5rem 0 0.5rem 2.5rem;
  max-width: 70ch;
}

.aui-lozenge-success {
  background-color: #e3fcef;
  color: rgb(0, 135, 90);
}

.aui-lozenge-progress {
  background-color: #fffae6;
  color: #974f0c;
}

.aui-lozenge-complete {
  background-color: #e9f4ff;
  color: #009fd8;
}

.aui-lozenge-error {
  background-color: #f7cbd2;
  color: rgb(242, 3, 3);
}

.aui-lozenge-success,
.aui-lozenge-error,
.aui-lozenge-complete,
.aui-lozenge-progress {
  padding: 4px 8px;
  text-align: center;
  border-radius: 5px;
  font-size: 11px;
  font-weight: 600;
  letter-spacing: 1px;
}

.confluenceTable {
  border-collapse: collapse;
  width: 100%;
  margin-bottom: 20px;
}

.confluenceTable li {
  margin: 0 !important;
}

.confluenceTh {
  text-align: left;
  border-bottom: 1px solid rgba(0, 0, 0, 0.13);
}

.confluenceTable tr td {
  border-bottom: 1px solid rgba(0, 0, 0, 0.13);
}

/* Panel macros general */
.confluence-information-macro-body p,
.panelContent p {
  margin: 0 !important;
}

.confluence-information-macro-body h2 {
  margin-top: 0;
}

.confluence-information-macro,
.panel {
  display: flex;
  align-items: center;
  min-height: 48px;
  margin: 2rem 0;
  padding: 16px 12px;
  border-radius: 3px;
}

.confluence-information-macro::before,
.panel::before {
  font-family: "Font Awesome 6 Free";
  display: inline-block;
  padding: 12px;
  vertical-align: middle;
  font-weight: 900;
  font-size: 24px;
}

/* Tip macro */
.confluence-information-macro-tip {
  background-color: #e3fcef;
}

.confluence-information-macro-tip::before {
  content: "\f058";
  color: var(--green);
}

/* note macro */
.confluence-information-macro-note {
  background-color: var(--yellow);
}

.confluence-information-macro-note::before {
  content: "\f071";
  color: var(--light-orange);
}

/* Warning macro */
.confluence-information-macro-warning {
  background-color: #f7cbd2;
}

.confluence-information-macro-warning::before {
  content: "\f00d";
  color: rgb(242, 3, 3);
}

/* Info macro */
.confluence-information-macro-information {
  background-color: #e9f4ff;
}

.confluence-information-macro-information::before {
  content: "\f05a";
  color: #009fd8;
}

.panel::before {
  content: "\f02d";
  color: #6c01b9;
}

.confluence-embedded-file-wrapper img {
  width: 100%;
}

#blog {
  background-color: #f0f8ff96;
}

@media (min-width: 1440px) {
  .blog {
    margin-top: 0;
  }
}
