.facts__container {
 flex-direction: column;
 justify-content: center;
 align-items: center;
 text-align: center;
 margin-top: 5vh;
 margin-bottom: 10vh;
}
.fact {
    max-width: 225px;
    padding: 1vh;
}
.fact__header {
    color: var(--light-orange);
    font-size: 2.5em;
    font-weight: 600;
}
.fact p {
    font-weight: 600;
    margin-top: 0;
    color: var(--dark-blue);
}

@media only screen and (min-width: 875px) {
    .facts__container {
        flex-direction: row;
    }
}