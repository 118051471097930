.community__cards {
  flex-wrap: wrap;
  justify-content: center;
  position: relative;
}

.community__card {
  margin-bottom: 1vh;
  padding: 2vh;
  transition: all 0.3s;
  width: 100%;
}

.community__card:hover {
  transform: translate(0, -5px);
  opacity: 0.8;
}

.comunnity__card_img_container {
  justify-content: center;
  align-items: center;
}

.community__card_img_border {
  aspect-ratio: 1/1;
  border-radius: 50%;
  width: 120px;
  height: fit-content;
  border: 3px solid var(--light-blue);
}

.community__card_img {
  aspect-ratio: 1/1;
  border-radius: 50%;
  background-repeat: no-repeat;
  background-size: 130%;
  background-position: center;
  /* filter: grayscale(1); */
  width: 120px;
}

.community__card_img.image-small {
  background-size: cover;
}

.community__card_body {
  text-align: center;
  padding: 1vh 20px;
}

.community__card_body p {
  margin: 0;
  font-size: 0.9em;
}

.community__card_info {
  font-size: 0.9em;
}

@media screen and (min-width: 780px) {
  .community__card {
    width: 27%;
  }
}

@media screen and (min-width: 1380px) {
  .community__card {
    width: 20%;
  }
}

@media screen and (min-width: 1680px) {
  .community__card {
    width: 16%;
  }
}
