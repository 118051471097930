@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;300;400;600;700&family=Raleway:wght@300;400;500;700&display=swap");

/* Global styling */
:root {
  /* DataHub old color scheme */
  --primary: hsl(201, 59%, 42%);
  --secondary: #ff6a00;
  --umc-dark-blue: #004289;
  --umc-seaweed-green: #437c17;
  --umc-warm-yellow: #f1c641;
  /* Bumba color scheme */
  --dark-blue: #001c3d;
  --light-blue: #009fd8;
  --dark-orange: #e54e10;
  --light-orange: #f09125;
  --red: #ae0b12;
  --green: #0f9a58;
  --yellow: #ffffc9;
  /* Text color */
  --gray90: #0a0a23;
  --gray85: #1b1b32;
  --gray80: #2a2a40;
  --gray75: #3b3b4f;
  --gray45: #858591;
  --gray15: #d0d0d5;
  --gray10: #e3e3e3;
  --gray05: #eeeef0;
  --gray00: #fff;
  /* Misc */
  --navbar-height: 80px;
}

* {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  padding: 0;
  font-family: "Poppins", sans-serif;
  box-sizing: border-box;
  overflow-x: hidden;
}

h1,
h2,
h3 {
  font-family: "Raleway", "Poppins", sans-serif;
}

.main__wrapper {
  width: 100vw;
  min-height: 100vh;
  background-color: white;
  position: relative;
}

.flex {
  display: flex;
}

.center {
  justify-content: center;
  align-items: center;
}

h3 {
  text-align: center;
  font-size: 36px;
  font-weight: 400;
}

/* Section styling and their SVG decorations*/

section {
  position: relative;
}

.section__content {
  padding: 100px 16px;
}

section h2,
h3 {
  color: var(--dark-blue);
  font-weight: 600;
  font-size: 36px;
  margin-bottom: 5vh;
  margin-top: 0;
  position: relative;
  text-align: center;
}

section p {
  font-size: 16px;
  font-weight: 400;
  line-height: 1.75;
  margin-top: 0;
  color: var(--gray90);
}

.custom-shape-divider-bottom {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  overflow: hidden;
  line-height: 0;
  transform: rotate(180deg);
}

.custom-shape-divider-bottom svg,
.custom-shape-divider-top svg {
  position: relative;
  display: block;
  width: calc(100% + 1.3px);
  height: 50px;
}

.custom-shape-divider-top svg {
  width: 200%;
}

.custom-shape-divider-bottom .shape-fill,
.custom-shape-divider-top .shape-fill {
  fill: #001c3d;
}

.custom-shape-divider-top {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  overflow: hidden;
  line-height: 0;
}

.dropzone {
  position: absolute;
  width: 120px;
  height: 140px;
  object-fit: cover;
}
.team {
  display: none;
  object-position: right;
  left: 0;
}
.collaborate {
  display: none;
  right: 0;
  bottom: 500px;
  object-position: left;
}

.dropzone {
  right: 0;
  top: -50px;
  object-position: left;
}

@media screen and (min-width: 900px) {
  .section__content {
    padding: 200px 16px;
    margin: 0 auto;
    max-width: 1000px;
  }
}

@media screen and (min-width: 1400px) {
  .collaborate,
  .team {
    display: block;
    position: absolute;
    width: 120px;
    height: 140px;
    object-fit: cover;
  }
  .section__content {
    max-width: 1200px;
  }
}

@media screen and (min-width: 2000px) {
  .section__content {
    max-width: 1400px;
  }
}
