/* SERVICES CARD */

.card {
  transition: all 400ms linear 0s;
  padding: 15px 15px 30px 15px;
  text-align: center;
  color: var(--gray90);
}
.card:not(:last-child) {
  margin-bottom: 5rem;
}
.card__header {
  height: 150px;
}
#MDR .card__header {
  height: 100px;
}
.card__header img {
  object-fit: contain;
  width: 100%;
  max-width: 100%;
  max-height: 100%;
}
.card__title {
  font-size: 1.2em;
  color: var(--dark-blue);
  font-weight: 600;
  margin-top: 12px;
  margin-bottom: 12px;
}

.card__description p {
  line-height: 1.7;
  font-weight: 500;
  max-width: 60ch;
  margin: 0 auto;
}

.card__footer_border {
  background-image: linear-gradient(
    to right,
    rgba(0, 0, 0, 0),
    rgb(255, 106, 0),
    rgba(0, 0, 0, 0)
  );
  border-top: none !important;
  height: 2px;
  background-color: transparent;
  border: 0;
  border-top-color: currentcolor;
  border-top-style: none;
  border-top-width: 0px;
  opacity: 0.95;
}
.card__footer {
  padding-top: 15px;
  min-height: 20px;
}
.card__footer a {
  text-decoration: none;
  text-transform: uppercase;
  padding: 5px 10px;
  transition: all 0.5s ease;
  position: relative;
  font-weight: 700;
  font-size: 0.85em;
  color: var(--dark-blue);
}

.card__footer a:hover {
  color: var(--secondary);
  border: none;
  font-size: 0.9em;
}

.card__footer svg {
  padding-right: 10px;
  font-size: smaller;
}

@media only screen and (min-width: 1175px) {
  .card {
    display: flex;
    justify-content: space-between;
    text-align: left;
  }
  .card__description p {
    max-width: unset;
  }
  .card:nth-of-type(even) {
    text-align: right;
  }
  .card__header {
    height: unset;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
  }
  .card__main {
    max-width: 70ch;
  }
  .card:nth-of-type(odd) {
    flex-direction: row-reverse;
  }
  .card:nth-of-type(odd) .card__header,
  #MDR .card__header {
    margin-left: 12px;
    height: unset;
  }
  .card__header img {
    max-width: 200px;
  }
  .card:nth-of-type(odd) .card__footer_border {
    background-image: linear-gradient(
      to left,
      rgba(0, 0, 0, 0),
      rgb(255, 106, 0)
    );
  }
  .card:nth-of-type(even) .card__footer_border {
    background-image: linear-gradient(
      to right,
      rgba(0, 0, 0, 0),
      rgb(255, 106, 0)
    );
  }
  .card__footer a {
    padding: 0;
  }
}
